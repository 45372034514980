TABLE_NS = {};

/**
  * Writes the head of the results table, based on the shift duration
  * @param {Number} [shiftDur] the total duration of the shift
  */
TABLE_NS.writeResHead = function(shiftDur) {

  let tr    = document.getElementById('table_res').tHead,
      th    = document.createElement('th');/*,
      div1  = document.createElement('div'),
      div2  = document.createElement('div'),
      span2 = document.createElement('span'),
      span1 = document.createElement('span');*/
  //th.innerHTML = 'Operator';
  //th.setAttribute('width',"150px");

  /*th.setAttribute('class',"background");
  span1.setAttribute('class',"bottom");
  span1.innerHTML = 'Operator';
  div1.appendChild(span1);
  span2.setAttribute('class',"top");
  span2.innerHTML = 'Time Unit';
  div1.appendChild(span2);
  div2.setAttribute('class','line');
  div1.appendChild(div2);
  th.appendChild(div1);

  tr.appendChild(th);*/
  for (let i=0; i<shiftDur; i++) {

    th = document.createElement('th');
    th.innerHTML = i + 1;
    tr.appendChild(th);
  }
};

/**
  * Writes a row in the results table for each operator and fills the first cell with operator info
  * @param {Array} [ops] array of all operators
  * @param {Number} [shiftDur] total duration of the shift
  */
TABLE_NS.writeResOps = function(ops, shiftDur) {

  let tbody = document.getElementById('table_res').tBodies[0],
      tr, td;
  for (let i=0, len=ops.length; i<len; i++) {

    tr = document.createElement('tr');
    // set the id of the row as the id of the operator
    tr.setAttribute('id', 'operator' + ops[i]['_id']);

    // create the first cell of the row, containing all the operator info
    td = document.createElement('td');
    td.innerHTML = `${ops[i].profile.firstname} ${ops[i].profile.lastname}`;
    td.setAttribute('data-toggle', 'tooltip');
    td.setAttribute('mdbTooltip', `id: ${ops[i]['_id']} \nteam: ${ops[i]['group']} \nbreak: ${ops[i]['breaks'][0]}`);
    td.setAttribute('placement', 'right');
    td.setAttribute('title', `id: ${ops[i]['_id']} \nteam: ${ops[i]['group']} \nbreak: ${ops[i]['breaks'][0]}`);

    tr.appendChild(td);
    // fill the row with a td for each time block
    for (let j=0; j<shiftDur; j++) {

      td = document.createElement('td');
      td.setAttribute('id', 'cell' + ops[i]['_id'] + '-' + (j + 1));
      tr.appendChild(td);
    }

    tbody.appendChild(tr);
  }
};

/**
  * Fills the operator rows in the results table with the tasks
  * @param {Array} [assgnms] array with all assignments
  * @param {Number} [nTasks] the number of tasks
  * @param {Array} [seq] sequence of nTasks colors (in hex format)
  */
TABLE_NS.fillResTasks = function (assgnms, nTasks, seq, tskInput) {

  let tr, td, duration, oprId, pri, start, tskId, tskDetails, reqStart;
  for (let i=0, len=assgnms.length; i<len; i++) {

    duration   = assgnms[i]['duration'];
    oprId      = assgnms[i]['oprId'];
    pri        = assgnms[i]['priority'];
    start      = assgnms[i]['startTime'];
    tskId      = assgnms[i]['tskId'];
    tskDetails = TABLE_NS.findTask(tskId, tskInput);
    reqStart   = tskDetails.timeBlock ? tskDetails.timeBlock : 'not specified';
    //color    = Math.floor((tskId - 1) * (16777215 / nTasks));
    // remove all cells occupied by the task except the first
    for (let j=1; j<duration; j++) {

      let element = document.getElementById('cell' + oprId + '-' + (start + j));
      element.parentNode.removeChild(element);
    }

    td = document.getElementById('cell' + oprId + '-' + start);
    // set the cell color based on the task id
    //td.style.backgroundColor = '#' + color.toString(16);
    td.style.backgroundColor = '#' + seq[tskId - 1];

    td.setAttribute('data-toggle', 'tooltip');
    td.setAttribute('mdbTooltip', `task id: ${tskId} \npriority: ${pri} \nstart time: ${reqStart} \nrequested operators: ${tskDetails.reqOps} \npreferred team: ${tskDetails.mainGroup} \nalternative team: ${tskDetails.secGroup} \npatient id: ${tskDetails.patId}`);
    td.setAttribute('placement', 'right');
    td.setAttribute('title', `task id: ${tskId} \npriority: ${pri} \nstart time: ${reqStart} \nrequested operators: ${tskDetails.reqOps} \npreferred team: ${tskDetails.mainGroup} \nalternative team: ${tskDetails.secGroup} \npatient id: ${tskDetails.patId}`);
    // extend the task cell to its duration
    td.setAttribute('colspan', `${duration}`);
    if (TABLE_NS.isDark(seq[tskId - 1]))
      td.style.color = '#FFFFFF';

    td.innerHTML = `Task ${tskId}`;
  }
};

/**
  * Find a task with a given id from an array
  * @param {Number} [id] the id of the task
  * @param {Array} [tskList] tasks array
  * @return {Object} the task with its properties
  */
TABLE_NS.findTask = function (id, tskList) {

  for (let i=0, len=tskList.length; i<len; i++) {

    if (tskList[i].id === id)
      return tskList[i];
  }
  return null;
};

/**
  * Determines if a color is dark or not
  * @param {String} [color] the to be examined color (in rgb format)
  * @return {Boolean} true if dark, false otherwise
  */
TABLE_NS.isDark = function ( color ) {
  let rgb = parseInt(color, 16);   // convert rrggbb to decimal
  let r   = (rgb >> 16) & 0xff;  // extract red
  let g   = (rgb >>  8) & 0xff;  // extract green
  let b   = (rgb >>  0) & 0xff;  // extract blue

  let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return (luma < 129)
};

/**
  * Calculates test statistics
  * @param {Array} [genTasks] tasks generated
  * @param {Array} [ops] operators
  * @param {Array} [ass] assignments
  * @param {Number} [nTasks] number of tasks
  * @param {Number} [shiftDur] shift duration
  */
TABLE_NS.calculateStats = function (genTasks, ops, ass, nTasks, shiftDur) {

  let assTasks   = [],
      unassTasks = [],
      groupStats = {},
      priStats   = {pri1: {id:1, ass: 0, tot: 0}, pri2: {id:2, ass: 0, tot: 0}, pri3: {id:3, ass: 0, tot: 0}},
      opsStats   = {},
      assDur     = 0,
      unassDur   = 0,
      totDur     = 0,
      assNum     = 0,
      unassNum   = 0,
      rightGroup = 0,
      tsks;

  for (let j=0, len=ops.length; j<len; j++) {

    opsStats['op' + ops[j]._id] = {

      name: `${ops[j].profile.firstname} ${ops[j].profile.lastname}`,
      assTime: 0,
      totTime: ops[j]['partTime'] ? (ops[j]['partTime'][1] - ops[j]['partTime'][0] + 1) : shiftDur,
      assTasks: 0
    };
    if (!groupStats['group' + ops[j]['group']]) {

      groupStats['group' + ops[j]['group']] = {

        id: ops[j]['group'],
        assNum: 0,
        opsNum: 1,
        transNum: 0,
        transTime: 0,
        assTime: 0,
        unassNum: 0,
        unassTime: 0,
        opsTime: ops[j]['partTime'] ? (ops[j]['partTime'][1] - ops[j]['partTime'][0] + 1) : shiftDur
      };
    }
    else {

      groupStats['group' + ops[j]['group']].opsNum += 1;
      groupStats['group' + ops[j]['group']].opsTime += ops[j]['partTime'] ? (ops[j]['partTime'][1] - ops[j]['partTime'][0] + 1) : shiftDur;
    }
  }

  for (let i=0, len=genTasks.length; i<len; i++) {

    tsks = TABLE_NS.searchByCriteria('tskId', genTasks[i]['id'], ass);
    priStats['pri' + genTasks[i]['priLvl']].tot += 1;
    totDur += genTasks[i]['tskDuration'];
    if (tsks.length) {

      assTasks.push(genTasks[i]);
      assDur += genTasks[i].tskDuration;
      assNum += 1;

      priStats['pri' + genTasks[i]['priLvl']].ass += 1;

      for (j=0, l=tsks.length; j<l; j++) {

        opsStats['op' + tsks[j].oprId].assTasks += 1;
        opsStats['op' + tsks[j].oprId].assTime += tsks[j].duration;

        groupStats['group' + tsks[j].group].assTime += tsks[j].duration;
        groupStats['group' + tsks[j].group].assNum += 1;
      }

      if (genTasks[i].mainGroup === tsks[0].group)
        rightGroup += 1;
      else {

        groupStats['group' + genTasks[i].mainGroup].transNum += 1;
        groupStats['group' + genTasks[i].mainGroup].transTime += tsks[0].duration;
      }
    }
    else {

      unassTasks.push(genTasks[i]);
      unassDur += genTasks[i].tskDuration;
      unassNum += 1;
      groupStats['group' + genTasks[i].mainGroup].unassNum += 1;
      groupStats['group' + genTasks[i].mainGroup].unassTime += genTasks[i].tskDuration;
    }
  }
  let needs = '';
  if (unassTasks.length) {

    let tot, rest, numFullTime, numHalfTime;
    needs += 'To assign all tasks, the following additional staff would be required:<br>';
    for (let prop in groupStats) {

      tot         = groupStats[prop].unassTime;
      numFullTime = Math.floor(tot / shiftDur);
      rest        = tot % shiftDur;
      if (tot && rest > (shiftDur / 2)) {

        numFullTime += 1;
        numHalfTime = 0;
        needs += `team ${groupStats[prop].id}: ${numFullTime} full-time operator<br>`;
      }
      else if (tot && numFullTime) {

        numHalfTime = 1;
        needs += `team ${groupStats[prop].id}: ${numFullTime} full-time operator and ${numHalfTime} half time operator<br>`;
      }
      else if (tot) {

        numHalfTime = 1;
        needs += `team ${groupStats[prop].id}: ${numHalfTime} half-time operator<br>`;
      }

    }
  }
  return {

    avgTime: totDur / nTasks,
    assTasks: assTasks,
    assTasksPer: Math.round(100 * assTasks.length / nTasks),
    unassTasks: unassTasks,
    unassTasksPer: Math.round(100 * unassTasks.length / nTasks),
    groupStats: groupStats,
    opsNeeds: needs,
    priStats: priStats,
    opsStats: opsStats,
    assTime: assDur,
    assTimePercent: Math.round(100 * assDur / totDur),
    unassTime: unassDur,
    unassTimePercent: Math.round(100 * unassDur / totDur),
    prefGroupPer: Math.round(100 * rightGroup / (assTasks.length > 0 ? assTasks.length : 1))
  }
}

/**
  * Writes the stats in the interface
  * @param {Object} [stats] object containing the stats
  */
TABLE_NS.writeStats = function (stats) {

  // write global stats
  /*document.getElementById('assTasks').innerHTML            = `${stats.assTasks.length} (${stats.assTasksPer}%)`;
  document.getElementById('assTasksTime').innerHTML        = `${stats.assTime} (${stats.assTimePercent}%)`;
  document.getElementById('unassTasks').innerHTML          = `${stats.unassTasks.length} (${stats.unassTasksPer}%)`;
  document.getElementById('unassTasksTime').innerHTML      = `${stats.unassTime} (${stats.unassTimePercent}%)`;
  document.getElementById('nottransferredTasks').innerHTML = `${stats.prefGroupPer}%`;*/
  document.getElementById('avgTime').innerHTML             = `${Math.round(100 * stats.avgTime) / 100}`;
  let tsk = document.getElementById("tasksChart").getContext('2d');
  let tskChart = new Chart(tsk, {
    type: 'pie',
    data: {
      labels: ["Assigned Tasks ", "Not Assigned Tasks "],
      datasets: [
        {
          data: [stats.assTasks.length, stats.unassTasks.length],
          backgroundColor: ["#3790bc", "#949FB1"],
          hoverBackgroundColor: ["#11507b", "#A8B3C5"]
        }
      ]
    },
    options: {
      responsive: true
    }
  });
  let tskTime = document.getElementById("tasksTimeChart").getContext('2d');
  let tskTimeChart = new Chart(tskTime, {
    type: 'pie',
    data: {
      labels: ["Assigned Time ", "Not Assigned Time "],
      datasets: [
        {
          data: [stats.assTime, stats.unassTime],
          backgroundColor: ["#3790bc", "#949FB1"],
          hoverBackgroundColor: ["#11507b", "#A8B3C5"]
        }
      ]
    },
    options: {
      responsive: true
    }
  });
  let prefGroup = document.getElementById("prefGroupChart").getContext('2d');
  let prefGroupChart = new Chart(prefGroup, {
    type: 'pie',
    data: {
      labels: ["Preferred Team (%) ", "Alternative Team (%) "],
      datasets: [
        {
          data: [stats.prefGroupPer, 100 - stats.prefGroupPer],
          backgroundColor: ["#3790bc", "#949FB1"],
          hoverBackgroundColor: ["#11507b", "#A8B3C5"]
        }
      ]
    },
    options: {
      responsive: true
    }
  });

  // write team stats
  let tbody = document.getElementById('teamStats').tBodies[0],
      tr, td;
  for (let prop in stats.groupStats) {

    tr = document.createElement('tr');
    tr.setAttribute('id', 'team' + stats.groupStats[prop].id);

    td = document.createElement('td');
    td.innerHTML = stats.groupStats[prop].id;
    tr.appendChild(td);

    td = document.createElement('td');
    td.innerHTML = stats.groupStats[prop].opsNum;
    tr.appendChild(td);

    td = document.createElement('td');
    td.innerHTML = stats.groupStats[prop].assNum;
    tr.appendChild(td);

    td = document.createElement('td');
    td.innerHTML = `${stats.groupStats[prop].assTime} (${Math.round(100 * stats.groupStats[prop].assTime / (stats.groupStats[prop].opsTime > 0 ? stats.groupStats[prop].opsTime : 1))}%)`;
    tr.appendChild(td);

    td = document.createElement('td');
    td.innerHTML = `${stats.groupStats[prop].transNum} (${stats.groupStats[prop].transTime})`;
    tr.appendChild(td);

    td = document.createElement('td');
    td.innerHTML = `${stats.groupStats[prop].opsTime - stats.groupStats[prop].assTime} (${Math.round(100 - 100 * stats.groupStats[prop].assTime / (stats.groupStats[prop].opsTime > 0 ? stats.groupStats[prop].opsTime : 1))}%)`;
    tr.appendChild(td);

    td = document.createElement('td');
    td.innerHTML = `${stats.groupStats[prop].unassNum} (${stats.groupStats[prop].unassTime})`;
    tr.appendChild(td);

    tbody.appendChild(tr);
  }

  // write priority stats
  tbody = document.getElementById('priorityStats').tBodies[0];
  for (let prop in stats.priStats) {

    tr = document.createElement('tr');
    tr.setAttribute('id', 'priority' + stats.priStats[prop].id);

    td = document.createElement('td');
    td.innerHTML = stats.priStats[prop].id;
    tr.appendChild(td);

    td = document.createElement('td');
    td.innerHTML = `${stats.priStats[prop].ass} (${Math.round(100 * stats.priStats[prop].ass / (stats.priStats[prop].tot > 0 ? stats.priStats[prop].tot : 1))}%)`;
    tr.appendChild(td);

    tbody.appendChild(tr);
  }

  // write operator stats
  tbody = document.getElementById('operatorStats').tBodies[0];
  for (let prop in stats.opsStats) {

    tr = document.createElement('tr');
    tr.setAttribute('id', '' + stats.opsStats[prop]);

    td = document.createElement('td');
    td.innerHTML = stats.opsStats[prop].name;
    tr.appendChild(td);

    td = document.createElement('td');
    td.innerHTML = `${stats.opsStats[prop].assTasks}`;
    tr.appendChild(td);

    td = document.createElement('td');
    td.innerHTML = `${stats.opsStats[prop].assTime} (${Math.round(100 * stats.opsStats[prop].assTime / (stats.opsStats[prop].totTime > 0 ? stats.opsStats[prop].totTime : 1))}%)`;
    tr.appendChild(td);

    tbody.appendChild(tr);
  }

  // write team needs stats
  let p = document.getElementById('teamNeeds');
  if (stats.opsNeeds) {

    p.innerHTML = stats.opsNeeds;
  }
  else {

    p.innerHTML = 'There is no need for additional operator in any team.';
  }
}

/**
  * Writes syntetic description of the results
  * @param {Object} [stats] object containing the stats
  */
TABLE_NS.writeSummary = function(stats) {

  let assSummary = document.getElementById('assTasksResume'),
      genSummary = document.getElementById('genTasksResume'),
      taskBar    = document.getElementById('taskBar'),
      needs      = document.getElementById('needs');

  assSummary.innerHTML = `${stats.assTasks.length}`;
  taskBar.innerHTML    = `${Math.round(100 * stats.assTasks.length / (stats.unassTasks.length + stats.assTasks.length))}%`; 
  taskBar.setAttribute('style', `width: ${Math.round(100 * stats.assTasks.length / (stats.unassTasks.length + stats.assTasks.length))}%`);
  taskBar.setAttribute('aria-valuenow', Math.round(100 * stats.assTasks.length / (stats.unassTasks.length + stats.assTasks.length)));
  genSummary.innerHTML = `${stats.assTasks.length + stats.unassTasks.length}`;
  if (stats.opsNeeds) {

    needs.innerHTML = stats.opsNeeds;
  }
  else {

    needs.innerHTML = 'There is no need for additional operators in any team.';
  }
};

/**
  * searches inside arrays of objects
  * @param {String} [criteria] the object property by which to search (e.g. search by id, ...)
  * @param {Object} [value] the value of the object property we are searching (can be a string, number, ecc...)
  * @param {Array} [array] array of searchable objects
  * @param {Boolean} [enableIndexes] if truthy collects all indexes (respective to the original array) of found elements
  * @return {Array} array with all found elements, if indexes are enabled the last term contains an array of their indexes
  */
TABLE_NS.searchByCriteria = function (criteria, value, array, enableIndexes) {

  let found   = [],
      indexes = [];

  for (let i=0, len=array.length; i<len; i++) {

    if (array[i][criteria] === value) {

      found.push(array[i]);
      indexes.push(i);
    }
  }
  if (enableIndexes)
    found.push(indexes);
  return found;
}
